import { TYPE } from "../../constants";

const defaultState = {
	token: null,
	user: {
		id: "",
		firstName: "",
		lastName: "",
		email: "",
	},
};

export default function AuthReducer(state = defaultState, action) {
	switch (action.type) {
		case TYPE.LOGIN_ACTION:
			return {
				...state,
				...action.payload,
			};

		case TYPE.LOGOUT_ACTION:
			return defaultState;

		default:
			return state;
	}
}
