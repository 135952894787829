import { TYPE } from "../../constants";

const initialState = {
	count: 0,
	cartCount: 0,
};

const wishlistReducer = (state = initialState, action) => {
	switch (action.type) {
		case TYPE.WISHLIST_COUNT:
			return {
				...state,
				count: action?.payload,
			};

		case TYPE.ADD_TO_CART_COUNT:
			return {
				...state,
				cartCount: action?.payload,
			};

		default:
			return state;
	}
};

export default wishlistReducer;
