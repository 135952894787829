import { combineReducers } from "redux";
import AuthReducer from "./auth.reducer";
import UserReducer from "./user.reducer";
import WishlistReducer from "./wishlist.reducer";
import multiReducer from "./multi.reducer";

export default combineReducers({
	auth: AuthReducer,
	user: UserReducer,
	wishlist: WishlistReducer,
	multiProduct: multiReducer,
});
